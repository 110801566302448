<template>
  <base-material-card
    :title="$t('invite', { name: $tc('studio') })"
    icon="$studio"
  >
    <form
      v-if="studio"
      action
      data-vv-scope="studio-form"
      @submit.prevent="validateForm('studio-form')"
    >
      <v-row>
        <v-col cols="12">
          <h3>Enviale la invitacion por email</h3>
        </v-col>
      </v-row>
      <v-row v-if="invited" justify="center">
        <v-col cols="6">
          <v-text-field
            outlined
            dense
            id="studioURL"
            readonly
            v-model="invited"
            :label="$t('studios.token')"
          >
            <template slot="prepend">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon class="mr-2" v-on="on" @click="copy('studioURL')"
                    >mdi-content-copy</v-icon
                  >
                </template>
                <span>{{ $t("copy") }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-else justify="center">
        <v-col class="xs" cols="12" md="3">
          <v-text-field
            outlined
            dense
            v-model="studio.user.email"
            data-vv-validate-on="blur"
            v-validate="'required|email'"
            :error-messages="errors.collect('studio-form.email')"
            color="secondary"
            data-vv-name="email"
            :label="$t('email')"
            prepend-icon="mdi-email"
          />
        </v-col>
        <v-col class="xs" cols="12" md="3">
          <v-text-field
            outlined
            dense
            v-model="studio.studio_name"
            data-vv-validate-on="blur"
            v-validate="'required|min:2'"
            :error-messages="errors.collect('studio-form.studio_name')"
            color="secondary"
            data-vv-name="studio_name"
            :label="$t('studios.name')"
            prepend-icon="$studio"
          />
        </v-col>
      </v-row>

      <studio-inscription
        v-if="!invited"
        ref="tattooerStudio"
        :tattooerStudio="studio.tattooerStudio"
        :buttons="false"
      ></studio-inscription>
      <div class="pa-3 text-right">
        <v-btn color="grey" @click="$router.back()">
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t("back") }}
        </v-btn>
        <v-btn color="success" @click="invited = null" v-if="invited">
          <v-icon>mdi-</v-icon>INVITAR A OTRO
        </v-btn>
        <v-btn color="success" type="submit" v-else>
          <v-icon>mdi-content-save</v-icon>
          {{ $t("invite", { name: $tc("studio") }) }}
        </v-btn>
      </div>
    </form>
  </base-material-card>
</template>
<script>
import { mapActions } from "vuex";

import * as moment from "moment";

import constants from "@/constants";
export default {
  name: "StudioInvite",
  components: {
    StudioInscription: () =>
      import("@/components/inscription/tattooer/StudioInscription"),
  },
  mounted() {
    this.today = moment().format("YYYY-MM-DD");
  },
  data() {
    return {
      studio: {
        user: { role_id: constants.roles.STUDIO },
        tattooerStudio: {
          timetable: [[], [], [], [], [], [], []],
          studio_id: this.$route.params.studio,
        },
      },
      today: null,
      menu: false,
      invited: null,
      hash: null,
    };
  },
  computed: {
    computedDateFormatted() {
      if (!this.studio.from) return null;
      const [year, month, day] = this.studio.from.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  methods: {
    ...mapActions("studios", ["inviteStudio"]),
    validateForm(scope) {
      this.errors.clear();
      this.userProcessed = false;
      let user_val = false;
      this.$refs.tattooerStudio.$validator
        .validateAll("user-form")
        .then((result) => {
          if (result) {
            user_val = true;
          }
          this.$validator.validateAll(scope).then((result) => {
            if (result && user_val) {
              //INVITAR
              //console.log("invite");
              this.inviteStudio({
                studio: this.studio,
              }).then((result) => {
                if (result.success) {
                  this.invited = result.url;
                  this.studio = {
                    user: { role_id: constants.roles.STUDIO },
                    tattooerStudio: {
                      timetable: [[], [], [], [], [], [], []],
                      studio_id: this.$route.params.studio,
                    },
                  };
                  this.$alert(this.$t("studio_invited_success"), "", "");
                }
              });
            }
          });
        });
    },
    copy(valor) {
      /* Get the text field */
      var copyText = document.getElementById(valor);

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.$alert(this.$t("link_copy_success"), "", "");
    },
  },
};
</script>